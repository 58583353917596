import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { API_BASE_URL_V } from "src/app/data-wp.service";
import { SocioData } from "src/app/shared/socio-data.interface";
import { HttpClient } from "@angular/common/http";


@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html',
  styleUrls: ['./perfil-usuario.component.css']
})
export class PerfilUsuarioComponent implements OnInit {

  constructor(private authSrv: AuthService, private http: HttpClient) { } // Inyecta HttpClient aquí

  datosSocio: SocioData;
  socio_nombre: string;
  socio_apellido: string;
  socio_numero: string;
  isLoading: boolean = false; // Variable para controlar el spinner


  ngOnInit() {
    this.datosSocio = JSON.parse(sessionStorage.getItem('data'));
    this.socio_numero = this.datosSocio.socio_numero;
    this.obtenerDatosSocio();
  }

  obtenerDatosSocio() {
    this.isLoading = true;
    this.http
      .post(API_BASE_URL_V + "/socio/obtener-plan-domicilio", {
        afiliado: this.datosSocio.socio_numero,
        dominio: this.datosSocio.socio_dominio
      })
      .subscribe((res: any) => {
        if (res && res.success && res.data) {
          this.datosSocio = { ...this.datosSocio, ...res.data };
        }
        this.isLoading = false;
      }, (error) => {
        console.error('Error al obtener los datos del socio:', error);
        this.isLoading = false;
      });
  }


  logout() {
    this.authSrv.logout();
  }
}
