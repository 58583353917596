import { Component, OnInit } from '@angular/core';
import { DataWpServiceChatbot } from '../../data-wp.service';
import { Observable } from 'rxjs';
import { PostI } from '../../post.interface';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.css'],
  providers: [DataWpServiceChatbot]
})
export class ChatbotComponent implements OnInit {
  product
  title

  constructor(
    private postsService: DataWpServiceChatbot
  ) { }

  posts$: Observable<PostI[]>;

  ngOnInit() {
    this.posts$ = this.postsService.getPosts();
    this.posts$.subscribe(data => { 
      /*Levantamos Data de chatbot*/
      var habilitar = this.product=data[0].acf.chatbot.habilitar;
      if(habilitar){
          //BOTMAKER
          let js = document.createElement('script');
          js.type = 'text/javascript';
          js.async = true;
          js.src = 'https://go.botmaker.com/rest/webchat/p/160WCSBUZ2/init.js';
          document.body.appendChild(js);
      }
    })  
  }
}
